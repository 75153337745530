.AuthApp {
    display: flex;
    align-items: center;
    height: 100%;
    background-color: #d2d6de;
    font-size: .875rem;
}

.AuthApp .wrapper {
    width: 100%;
    max-width: 410px;
    padding-top: 20px;
    padding-bottom: 20px;
    margin: auto;
    background-color: #ffffff;
}

.AuthApp label {
    font-weight: bold;
}