html, body {
  height: 100%;
}

body {
  font-size: .875rem;
}

#root {
    height: 100%;
    color: #2a2a2b
}

.App .react-datepicker-popper {
  z-index: 100;
}

.nav-tabs .nav-link, .nav-pills .nav-link {
  cursor: pointer;
}

.nav-pills a.nav-link.active:not([href]):not([tabindex]):focus, 
.nav-pills a.nav-link.active:not([href]):not([tabindex]):hover {
  color: #ffffff;
  text-decoration: none;
}

.feather-flip {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
  transform: scaleX(-1) !important; 
  color: #999 !important;  
}

.sidebar .nav-link.active .feather-flip{
  color: #007bff !important;  
}

.feather-flip:hover {
  color: #333 !important;
}

.growing-circles {
    margin-bottom: 26px;
}

.feather {
  width: 16px;
  height: 16px;
  vertical-align: text-bottom;
}

.main-content {
  top: 48px;
  margin-bottom: 40px;
}

/*
 * Sidebar
 */

.sidebar {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  z-index: 100; /* Behind the navbar */
  padding: 0;
  box-shadow: inset -1px 0 0 rgba(0, 0, 0, .1);
}

.sidebar-sticky {
  position: -webkit-sticky;
  position: sticky;
  top: 128px; /* Height of navbar + footer */
  height: calc(100vh - 128px);
  padding-top: .5rem;
  overflow-x: hidden;
  overflow-y: auto; /* Scrollable contents if viewport is shorter than content. */
  margin-bottom: 60px; /* height of footer */
}

.sidebar .nav-link {
  font-weight: 500;
  color: #333;
}

.sidebar .nav-link .feather {
  margin-right: 4px;
  color: #999;
}

.sidebar .nav-link.active {
  color: #007bff;
}

.sidebar .nav-link:hover .feather,
.sidebar .nav-link.active .feather {
  color: inherit;
}

.sidebar-heading {
  font-size: .75rem;
  text-transform: uppercase;
}

/*
 * Navbar
 */

.navbar-brand {
  padding-top: .75rem;
  padding-bottom: .75rem;
  font-size: 1rem;
}

.navbar .form-control {
  padding: .75rem 1rem;
  border-width: 0;
  border-radius: 0;
}

.form-control-dark {
  color: #fff;
  background-color: rgba(255, 255, 255, .1);
  border-color: rgba(255, 255, 255, .1);
}

.form-control-dark:focus {
  border-color: transparent;
  box-shadow: 0 0 0 3px rgba(255, 255, 255, .25);
}

.activeTenantLabel {
  font-size: 1.3em; 
  color: white; 
  margin: 0px 20px;
}

.nav-item .badge {
  font-size: 12px !important;
  margin-left: 5px !important;
}


/* Mobile Nav */
.mobile-nav {
display: none;
}

.mobile-nav .nav-item {
  margin-left: 10px;
}

.navbar-collapse .nav-link.btn{
  display: inline !important;
}

/*
 * Utilities
 */

.border-top { border-top: 1px solid #e5e5e5; }
.border-bottom { border-bottom: 1px solid #e5e5e5; }

._loading_overlay_content {
    margin-top: 150px !important;
    
}

._loading_overlay_overlay {
    min-height: 385px !important;
}

textarea:focus, input:focus{
    outline: none !important;
    box-shadow: none !important;
}

.table-data-search .form-group {
    margin: 0;
}

.has-error input {
  border: 1px solid #b73a3a;
  background-color: #fcf2f2 !important;
}

.has-error label {
  color: #b73a3a;
  font-weight: bold;
}

.form-group.datepicker .react-datepicker-wrapper {
  display: block;
}

.form-group.datepicker .react-datepicker__input-container {
  display: block;
}

/*
* Classification Type Cards
*/ 

.card {
  margin-bottom: 10px;
  -webkit-box-shadow: 3px 3px 5px 2px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 2px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 2px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */  
}
.card.active {
  border-color: #007bff;
  background: #007bff;
  color: white;
}

.classificationTypeCard.active .card-text {
  color: white;
}

.classificationTypeCard:hover {
  border-color: #007bff;
}

.classificationTypeCard .card-title {
  font-size: 1.6em;
  text-align: right;
  font-weight: bold;
}

.classificationTypeCard .card-text {
  font-size: 1em;
  text-align: right;
  text-transform: uppercase;
  color: #999;
}

.classificationTypeCard .feather {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: white;
  text-align: center;
  background: #dc3545;
  position: absolute;
  top: 15px; 
  padding: 25px; 
} 

.classificationTypeCard .critical{
background: #dc3545;
}

.classificationTypeCard .major{
background: #ffc107;
}

.classificationTypeCard .minor {
background: #6c757d;
}

/*
* Company Type Cards
*/ 

.companyTypeCard.active {
  border-color: #007bff;
  background: #007bff;
  color: white;
}

.companyTypeCard.active .card-text {
  color: white;
}

.companyTypeCard:hover {
  border-color: #007bff;
}

.companyTypeCard .card-title {
  font-size: 1.6em;
  text-align: right;
  font-weight: bold;
}

.companyTypeCard .card-text {
  font-size: 1em;
  text-align: right;
  text-transform: uppercase;
  color: #999;
}

.companyTypeCard .feather {
  width: 80px;
  height: 80px;
  border-radius: 50%;
  color: white;
  text-align: center;
  background: #dc3545;
  position: absolute;
  top: 15px; 
  padding: 25px; 
} 

.companyTypeCard .customer{
background: #ffc107;
}

.companyTypeCard .customerAndSupplier{
background: #28a745;
}

.companyTypeCard .supplier {
background: #007bff;
}

/*
* Button 
*/

.btn-group-sm>.btn, .btn-sm {
  padding: .375rem .75rem;
}

/*
* Tabs
*/

.tab-content .tab-pane {
  padding: 10px 0px 10px 0px;
}

/*
* Form
*/

label {
  font-weight: 600;
}

/*
* Overview Badges
*/

.OverviewBadge {
  float: right;
}

.OverviewBadge .badge {
  margin-left: 5px;
}

/*
* List Group
*/

.list-group-item.active {
  background-color: #343a40;
  border: #343a40;
}

.list-group-item {
  -webkit-box-shadow: 3px 3px 5px 2px #ccc;  /* Safari 3-4, iOS 4.0.2 - 4.2, Android 2.3+ */
  -moz-box-shadow:    3px 3px 5px 2px #ccc;  /* Firefox 3.5 - 3.6 */
  box-shadow:         3px 3px 5px 2px #ccc;  /* Opera 10.5, IE 9, Firefox 4+, Chrome 6+, iOS 5 */  
}

/* Dashboard */

.stat-box {
  padding: 20px;
}
.stat-box p {
  font-size: 1.2em;
  
}
.stat-box .card-title {
  font-size: 3em;

  font-weight: bold;
}

.stat-box .card-text{
  font-size: 1em;  
}

.stat-box.increase .feather{
  width: 20px;
  height: 20px;
  color: #dc3545;
}

.stat-box.decrease .feather{
  width: 20px;
  height: 20px;
  color: #28a745; 
}

/* Form Contacts */

.addContactBtn {
  margin-top: 30px !important;
}

.removeContactBtn {
  margin-top: 10px !important;
}

@media only screen and (max-width: 575px) {

  /* Navigation */
  .mobile-nav {
    display: block;
    background: #fff;
  }

  .navbar-dark .navbar-nav .nav-link {
    color: black;
  }

  .navbar-dark .navbar-nav .active>.nav-link, 
  .navbar-dark .navbar-nav .nav-link.active, 
  .navbar-dark .navbar-nav .nav-link.show, 
  .navbar-dark .navbar-nav .show>.nav-link,
  .navbar-dark .navbar-nav .nav-link:hover {
    color: blue;
  }

  .navbar-dark .navbar-nav .nav-link.logged-in {
    color: white !important;
  }

  .activeTenantLabel {
    margin: 0px !important;  
    margin-left: 10px !important;
    margin-right: 20px !important;
    margin-top: 15px !important; 
    display: inline-block !important;
  }

  /* Form */
  .nav-tabs .nav-item {
    width: 100% !important;
}

/* Contacts */
  .addContactBtn {
    margin-top: 10px !important;
    margin-right: 10px !important;
    margin-bottom: 10px !important;
  }
  
  .removeContactBtn {
    margin-top: 10px !important;
    margin-left: 10px !important;
    margin-bottom: 10px !important;
  }
}